import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';

import { currencyFormat, isInternation } from '../utils/util';
import { PAYMENT_MODES } from '../utils/constants';

import Actions from '../actions';

import AppBar from '../components/header/AppBar';
import CheckImg from '../assets/images/check.png';
import BorderCheckImg from '../assets/images/border_check.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: '80vh',
    },
    container: {
      width: '85%',
      maxWidth: 1068,
      padding: 30,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    submit: {
      ...theme.button,
      minWidth: 400,
      [theme.breakpoints.down('sm')]: {
        minWidth: 300,
        marginTop: 15,
        fontSize: '21px !important',
      },
    },
    text: {
      color: theme.textColor,
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
    },
    lockContainer: {
      marginTop: '15px !important',
    },
    icon: {
      color: theme.textColor,
      fontSize: '25px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important',
      },
      marginRight: 8,
    },
    checkIcon: {
      fontSize: '16px !important',
      color: '#595959',
      marginRight: 8,
    },
    planTextContainer: {
      padding: '48px 62px',
      background: '#fafafa',
      borderRadius: 24,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        background: '#fff',
      },
    },
    textContainer: {
      marginTop: 15,
    },
    planText: {
      fontSize: '14px !important',
      color: '#595959',
      textAlign: 'left',
    },
    titleContainer: {
      paddingBottom: 8,
    },
    planTextTitle: {
      color: '#333',
      fontSize: '18px !important',
      lineHeight: 1.2,
      fontWeight: 'bold !important',
    },
    offersContainer: {
      padding: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 30,
      },
    },
    offerContainer: {
      background: '#fff7f2',
      minHeight: 100,
      borderRadius: 20,
      marginTop: 15,
      marginBottom: 15,
      cursor: 'pointer',
      border: '2px solid #fff7f2',
      [theme.breakpoints.down('sm')]: {
        minHeight: 80,
      },
    },
    active: {
      border: '2px solid #b37a58',
      background: '#ffceb1',
    },
    offer: {
      minHeight: 100,
      padding: '0px 30px',
      [theme.breakpoints.down('sm')]: {
        minHeight: 80,
        padding: '0px 30px',
      },
    },
    offerText: {
      color: '#4c3425',
      fontSize: '20px !important',
      fontWeight: '600 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
        fontWeight: '600 !important',
      },
    },
    imgContainer: {
      marginRight: '15px !important',
      width: 28,
      height: 28,
    },
    blankImgContainer: {
      border: '1px solid #4c3425',
      borderRadius: '50%',
      width: 24,
      height: 24,
    },
    checkImg: {
      width: 28,
      height: 28,
    },
    offerTitleContainer: {
      padding: '18px 41px 18px 42px',
      [theme.breakpoints.down('sm')]: {
        padding: '14px 35px',
      },
    },
    borderCheckImg: {
      width: 20,
      height: 20,
      [theme.breakpoints.down('sm')]: {
        width: 16,
        height: 16,
      },
      marginRight: 5,
    },
    offerTitle: {
      color: '#4c3425',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px !important',
      },
    },
  };
});

const TEXTS = [
  'One-on-one speech therapy sessions delivered online.',
  'Available at nights or weekends at your convenience.',
  'Free cancellations and pro rated refunds.',
  'World class speech therapy at a fraction of the cost.',
];

function Purchase(props) {
  const {
    user, createOrder, orderObj, getOffers, offerObj,
  } = props;
  const classes = useStyles();
  const [selectedOffer, setSelectedOffer] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);

  const setCrrentoffer = (offer) => {
    let paymentMode = PAYMENT_MODES.RAZORPAY;
    let platform = '';
    if (offer.currency !== 'INR') {
      paymentMode = PAYMENT_MODES.STRIPE;
      platform = 'web';
    }
    setSelectedOffer({
      offerId: offer._id, paymentMode, platform, sessions: offer.totalSessions,
    });
  };

  const openUrl = (url) => {
    setRedirect(true);
    setTimeout(() => {
      window.open(url, '_blank').focus();
    }, 100);
  };

  const submit = () => {
    if (!selectedOffer.offerId || !user) return;
    createOrder({ ...selectedOffer, uid: user.uid });
  };

  React.useEffect(() => {
    if (orderObj && orderObj.order) return openUrl(orderObj.order && orderObj.order.paymentLink);
    return null;
  }, [orderObj]);

  React.useEffect(() => {
    const personalTherapyRegionId = isInternation(user.phone) ? 'default_USD' : '4vZQ1hv9gNxclBRlOKEf';
    if (user) getOffers({ uid: user.uid, personalTherapyRegionId });
  }, [user]);

  React.useEffect(() => {
    const offers = (offerObj && offerObj.offers) || [];
    const offer = offers.find((obj) => { return obj.isPopular === true; });
    if (offer) setCrrentoffer(offer);
  }, [offerObj]);

  if (!user) {
    return (<Redirect to="/" />);
  }

  if (redirect) {
    return (<Redirect to="/payment" />);
  }

  return (
    <>
      <AppBar>
        <Grid container className={classes.root} justifyContent="center" alignItems="center">
          <Grid className={classes.container}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid container item md={7} sm={12} xs={12} className={classes.planTextContainer} justifyContent="center" alignItems="center">
                <Grid item md={12} sm={12} xs={12} className={classes.titleContainer}>
                  <Typography align="left" className={classes.planTextTitle}>Every Plan Includes:</Typography>
                </Grid>
                {
                  TEXTS.map((text) => {
                    return (
                      <Grid container className={classes.textContainer} key={text} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={1}>
                          <CheckIcon className={classes.checkIcon} />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography className={classes.planText}>
                            {text}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </Grid>
              <Grid item md={5} sm={12} xs={12} className={classes.offersContainer}>
                {
                  offerObj && offerObj.offers.map((offer) => {
                    return (
                      <Grid
                        key={offer._id}
                        container
                        className={selectedOffer.offerId !== offer._id ? classes.offerContainer : [classes.offerContainer, classes.active].join(' ')}
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={() => { setCrrentoffer(offer); }}
                      >
                        <Grid container justifyContent="space-between" className={classes.offer} alignItems="center">
                          <Grid item>
                            <Grid container justifyContent="center" alignItems="center">
                              <Grid item className={classes.imgContainer}>
                                {
                                  selectedOffer.offerId === offer._id
                                    ? <img className={classes.checkImg} width="28px" height="28px" src={CheckImg} alt="check" />
                                    : <div className={classes.blankImgContainer} />
                                }
                              </Grid>
                              <Grid item>
                                <Typography className={classes.offerText}>
                                  {offer.totalSessions}
                                  {' '}
                                  Sessions
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Typography className={classes.offerText}>{currencyFormat(offer.discountedPrice, offer.currency)}</Typography>
                          </Grid>
                        </Grid>
                        {
                          offer.specials && offer.specials.length !== 0
                            ? (
                              <Grid container className={classes.offerTitleContainer}>
                                {
                                  offer.specials.map((text) => {
                                    return (
                                      <Grid item key={text} container alignItems="center">
                                        <Grid item xs={1}>
                                          <img className={classes.borderCheckImg} src={BorderCheckImg} alt="border-check" />
                                        </Grid>
                                        <Grid item xs={11}>
                                          <Typography align="left" className={classes.offerTitle}>{text}</Typography>
                                        </Grid>
                                      </Grid>
                                    );
                                  })
                                }
                              </Grid>
                            ) : null
                        }
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <button type="button" className={classes.submit} disabled={!selectedOffer.offerId} onClick={submit}>
                  Buy
                  {' '}
                  {selectedOffer.sessions ? selectedOffer.sessions : ''}
                  {' '}
                  Sessions
                </button>
              </Grid>
              <Grid item className={classes.lockContainer} container justifyContent="center" alignItems="center">
                <Grid>
                  <LockIcon className={classes.icon} />
                </Grid>
                <Grid>
                  <Typography className={classes.text}>
                    Secure checkout with
                    {' '}
                    {selectedOffer.paymentMode ? selectedOffer.paymentMode : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    orderObj: state.session.order,
    offerObj: state.session.offers,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Purchase));
