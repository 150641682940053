import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Actions from '../../actions';

import { userValidationSchema, checkAndGetNumber, validateEmail } from '../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    sectionContainer: {
      paddingBottom: 0,
      width: '100%',
    },
    paperContainer: {
      marginTop: 15,
      width: '100%',
    },
    inputContainer: {
      textAlign: 'left',
      padding: '8px 16px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        paddingBottom: 16,
      },
      width: '100%',
    },
    input: {
      border: '2px solid transparent',
      borderRadius: 8,
      outline: '1px solid #ffceb1',
      height: 56,
      fontSize: 16,
      color: '#4c3425',
      background: '#fff',
      width: '100%',
      padding: '18px',
      maxWidth: 500,
    },
    inputControl: {
    },
    textInput: {
    },
    phoneInput: {
    },
    codeInput: {
      maxWidth: 64,
    },
    focus: {
      border: '1px solid #000',
    },
    inputTitle: {
      padding: 5,
      fontSize: 14,
      color: '#333',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    submit: {
      ...theme.button,
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  };
});

function Profile(props) {
  const {
    setSnack, setLoading, fetchOrCreateUser, fetchUser, done, uid, user, updateUser,
  } = props;
  const classes = useStyles();
  const getInitialState = (obj = {}) => {
    return {
      name: obj.name || '',
      email: obj.email || '',
      mobile: obj.mobile || '',
      code: obj.code || '+1',
      phone: obj.phone || '',
      concern: obj.concern || '',
      timezone: obj.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  };
  const [data, setData] = React.useState(getInitialState());

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const updateUserData = () => {
    const phone = checkAndGetNumber(data.phone);
    const email = data.email && data.email.trim();
    const name = data.name && data.name.trim();
    if (!phone || phone.length < 8) {
      setSnack({
        message: 'Invalid phone number!',
        severity: 'warning',
      });
      return null;
    }
    if (!name) {
      setSnack({
        message: 'Invalid user name!',
        severity: 'warning',
      });
      return null;
    }
    if (!email || !validateEmail(email)) {
      setSnack({
        message: 'Invalid email id!',
        severity: 'warning',
      });
      return null;
    }
    return updateUser({
      uid, phone, email, name, concern: data.concern,
    }).then(() => {
      setSnack({
        message: 'Data updated successfully!',
        severity: 'success',
      });
    });
  };

  const onSubmit = () => {
    if (uid) return updateUserData();
    const inputData = {};
    Object.keys(data).forEach((key) => {
      inputData[key] = data[key] && data[key].trim();
    });
    inputData.phone = data.code + data.mobile;
    if (!inputData.code || !inputData.code.startsWith('+')) {
      setSnack({
        message: 'Invalid country code!',
        severity: 'warning',
      });
      return null;
    }
    if (!inputData.phone || inputData.phone.length <= 10) {
      setSnack({
        message: 'Invalid phone number!',
        severity: 'warning',
      });
      return null;
    }
    setLoading(true);
    return userValidationSchema.validate(inputData)
      .then(() => {
        return fetchOrCreateUser(inputData);
      })
      .then(() => { return done(); })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (uid) fetchUser({ id: uid });
  }, [uid]);

  React.useEffect(() => {
    if (user && user.uid) setData(getInitialState(user));
  }, [user]);

  return (
    <Grid item container xs={12} justifyContent="center" alignItems="center">
      <Grid item md={11} sm={12} xs={12}>
        <Paper elevation={0} className={classes.paperContainer}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container justifyContent="center" className={classes.sectionContainer}>
              <Grid item md={6} sm={6} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>Full Name</p>
                <div className={classes.inputControl}>
                  <input
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                    className={[classes.input, classes.textInput].join(' ')}
                    required
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>Email Address</p>
                <div className={classes.inputControl}>
                  <input
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className={[classes.input, classes.textInput].join(' ')}
                    required
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" className={classes.sectionContainer}>
              <Grid item md={6} sm={6} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>Mobile Number</p>
                {
                  uid
                    ? (
                      <div className={classes.inputControl}>
                        <input
                          type="text"
                          name="phone"
                          value={data.phone}
                          onChange={handleChange}
                          className={[classes.input, classes.textInput].join(' ')}
                          required
                        />
                      </div>
                    )
                    : (
                      <Grid container>
                        <Grid item style={{ paddingRight: 16 }}>
                          <div className={classes.inputControl}>
                            <input
                              type="text"
                              name="code"
                              value={data.code}
                              onChange={handleChange}
                              className={[classes.input, classes.codeInput].join(' ')}
                              required
                            />
                          </div>
                        </Grid>
                        <Grid item xs>
                          <div className={classes.inputControl}>
                            <input
                              type="number"
                              name="mobile"
                              value={data.mobile}
                              onChange={handleChange}
                              className={[classes.input, classes.phoneInput].join(' ')}
                              required
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )
                }
              </Grid>
              <Grid item md={6} sm={6} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>What are your communication concerns?</p>
                <div className={classes.inputControl}>
                  <input
                    type="text"
                    name="concern"
                    value={data.concern}
                    onChange={handleChange}
                    className={[classes.input, classes.textInput].join(' ')}
                    required
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container justifyContent="center">
          <button type="button" className={classes.submit} onClick={onSubmit}>
            {user ? 'Save' : 'Choose Time Slot'}
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
