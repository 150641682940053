const initialState = {
  fetching: false,
};

export default function sessionReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'FETCHING_REMAINING_SESSIONS':
      return {
        ...state,
        fetching: true,
      };
    case 'FETCHED_REMAINING_SESSIONS':
      return {
        ...state,
        fetching: false,
        count: payload,
      };
    case 'FETCHING_USER_THERAPIST':
      return {
        ...state,
      };
    case 'FETCHED_USER_THERAPIST':
      return {
        ...state,
        therapist: payload,
      };
    case 'CANCELLING_BOOKING':
      return {
        ...state,
      };
    case 'CANCELLED_BOOKING':
      return {
        ...state,
      };
    case 'INSERTING_SESSION_FEEDBACK':
      return {
        ...state,
      };
    case 'INSERTED_SESSION_FEEDBACK':
      return {
        ...state,
      };
    case 'FETCHING_OFFERS':
      return {
        ...state,
      };
    case 'FETCHED_OFFERS':
      return {
        ...state,
        offers: payload,
      };
    case 'CREATING_ORDER':
      return {
        ...state,
      };
    case 'CREATED_ORDER':
      return {
        ...state,
        order: payload,
      };
    case 'RESET_ORDER':
      return {
        ...state,
        order: null,
      };
    case 'RESET_REMAINING_SESSIONS':
      return {
        ...state,
        fetching: false,
        count: null,
      };
    default:
      return state;
  }
}
