import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// import { isInternation } from '../../../utils/util';

import Config from '../../../config.json';

import Actions from '../../../actions';

import Modal from '../../app/Modal';

import Therapist from '../../Therapist';

const useStyles = makeStyles((theme) => {
  return {
    container: {
    },
    title: {
      fontSize: 32,
      textAlign: 'center',
      marginTop: '30px',
      marginBottom: '30px',
      color: '#333',
      fontWeight: 600,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    weekDay: {
      fontWeight: 'bold',
      fontSize: 20,
      color: '#555',
      margin: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    day: {
      fontSize: 16,
      color: '#333',
      margin: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    slotButton: {
      fontWeight: 'bold',
      fontSize: 20,
      border: 'none',
      background: '#fff',
      padding: '20px 30px',
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        padding: 20,
      },
      marginBottom: 8,
    },
    enabled: {
      color: '#007dd6',
      '&:hover': {
        background: theme.hoverColor,
        color: '#4c3425',
      },
      cursor: 'pointer',
    },
    disabled: {
      color: '#888',
    },
    move: {
      background: 'transparent',
      border: 'none',
      padding: '10px 20px',
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 105,
    },
    moveEnable: {
      color: theme.primaryColor,
      cursor: 'pointer',
      '&:hover': {
        background: theme.hoverColor,
        color: theme.secondaryColor,
      },
    },
    confirm: theme.button,
    date: {
      fontSize: 20,
      color: '#333',
      margin: 0,
      marginTop: 30,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    time: {
      fontSize: 22,
      fontWeight: 'bold',
      color: '#333',
      margin: 0,
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    checkboxControl: {
      height: 120,
      borderRadius: 24,
      padding: '0 15px',
      margin: '0px !important',
      width: '100% !important',
    },
    textContainer: {
      marginTop: 8,
      borderLeft: '1px solid #ccc',
      paddingLeft: 24,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 12,
      },
    },
    checkbox: {
      marginRight: '15px !important',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px !important',
      },
    },
    text: {
      fontSize: '20px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
      },
    },
  };
});

const getDays = () => {
  const width = window.innerWidth;
  if (width >= 760) return 5;
  if (width > 460 && width < 760) return 4;
  if (width <= 460) return 3;
  return 3;
};

function Step2(props) {
  const {
    user, fetchSlots, slots, checkAvailableSession, session, createBooking, resetSession, location,
    resheduleBooking, updateUser, loadingSessionData,
  } = props;
  const { bookingId, source } = (location && location.state) || {};
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const classes = useStyles();
  const keys = Object.keys(slots || {});
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [startDate, setStartDate] = React.useState(now);
  const [sendWhatsapp, setSendWhatsapp] = React.useState(true);
  const [slot, setSlot] = React.useState({});
  const [redirectHome, setRedirectHome] = React.useState(false);
  const [redirectPurchase, setRedirectPruchase] = React.useState(false);
  const [selectTherapist, setSelectTherapist] = React.useState(false);
  const days = getDays();

  const confirm = () => {
    if (slot && user) {
      const data = {
        uid: user.uid, sendWhatsapp, startTime: slot.date, therapist: slot.therapist,
      };
      const promise = bookingId ? resheduleBooking({ ...data, bookingId }) : createBooking(data);
      promise.then(() => {
        setRedirectHome(true);
        if (bookingId) window.history.replaceState({}, document.title);
        if (Config.projectId !== 'stamurai-89397') window.gtag_report_conversion();
      }).catch();
    }
  };

  const openModal = (obj) => {
    setOpen(true);
    setSlot(obj);
  };

  const closeModal = () => {
    setOpen(false);
    setSlot({});
  };

  const onCloseErrorModal = () => {
    setOpenError(false);
    resetSession();
    setRedirectHome(true);
  };

  const handlePrevDate = () => {
    if (now < startDate) {
      const newDate = moment(startDate).subtract(days, 'days').toDate();
      setStartDate(newDate);
    }
  };

  const handleNextDate = () => {
    const newDate = moment(startDate).add(days, 'days').toDate();
    setStartDate(newDate);
  };

  const goto = () => {
    resetSession();
    setRedirectPruchase(true);
  };

  const submitTherapistPreference = (data) => {
    updateUser(data);
    setSelectTherapist(false);
  };

  React.useEffect(() => {
    if (!user || !user.uid) setRedirectHome(true);
  }, [user]);

  React.useEffect(() => {
    if (user && user.uid) checkAvailableSession({ uid: user.uid, source });
  }, []);

  React.useEffect(() => {
    if (!selectTherapist && user && user.uid && (bookingId || (session && session.hasSession))) fetchSlots({ uid: user.uid, date: startDate, days });
  }, [user, startDate, days, session, selectTherapist]);

  React.useEffect(() => {
    if (!bookingId && session && !session.hasSession && !loadingSessionData) setOpenError(true);
  }, [session]);

  // React.useEffect(() => {
  //   if (user && !user.therapistPreference && user.phone && isInternation(user.phone)) setSelectTherapist(true);
  // }, [user]);

  if (redirectHome) {
    return <Redirect to="/home" />;
  }

  if (redirectPurchase && user) {
    return <Redirect to="/purchase" push />;
  }

  return (
    <>
      <Modal maxWidth="xs" open={openError && !selectTherapist} handleClose={onCloseErrorModal}>
        <Grid container justifyContent="center">
          <p className={classes.date}>
            Please purchase more sessions to schedule a session with the therapist.
            Clicking the below button will launch the purchase screen.
          </p>
          <button type="button" onClick={goto} className={classes.confirm}>
            Purchase Sessions
          </button>
        </Grid>
      </Modal>
      <Modal open={open} maxWidth="xs" titleAlign="flex-start" titleSize="h5" handleClose={closeModal} title="Confirm Booking">
        <Grid>
          <Grid>
            <p className={classes.date}>
              {new Date(slot.date).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'short' })}
            </p>
            <p className={classes.time}>
              {new Date(slot.date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
            </p>
          </Grid>
          <Grid>
            <FormControl fullWidth style={{ padding: '10px 0' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={sendWhatsapp}
                    size="large"
                    sx={{
                      color: sendWhatsapp ? '#4caf50' : '#333333',
                      '&.Mui-checked': {
                        color: sendWhatsapp ? '#4caf50' : '#333333',
                      },
                    }}
                    className={classes.checkbox}
                    onClick={() => { setSendWhatsapp(!sendWhatsapp); }}
                  />
                )}
                className={classes.checkboxControl}
                style={{ background: sendWhatsapp ? '#edf7ee' : '#fafafa' }}
                label={(
                  <Grid container alignItems="center" className={classes.textContainer}>
                    <Grid item xs={2}>
                      <WhatsAppIcon fontSize="large" style={{ color: '#4caf50' }} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.text}>Send me the slot details on WhatsApp</Typography>
                    </Grid>
                  </Grid>
                    )}
              />
            </FormControl>
          </Grid>
          <Grid container justifyContent="center">
            <button type="button" onClick={confirm} className={classes.confirm}>
              Confirm
            </button>
          </Grid>
        </Grid>
      </Modal>
      {
        selectTherapist ? <Therapist uid={user && user.uid} submitTherapistPreference={submitTherapistPreference} />
          : (
            <Container className={classes.container}>
              <Grid>
                <p className={classes.title}>Choose A Time Slot</p>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <button
                    type="button"
                    className={[classes.move, now >= startDate ? classes.disabled : classes.moveEnable].join(' ')}
                    onClick={handlePrevDate}
                  >
                    <NavigateBeforeIcon />
                    Prev
                  </button>
                </Grid>
                <Grid item>
                  <button type="button" className={[classes.move, classes.moveEnable].join(' ')} onClick={handleNextDate}>
                    Next
                    <NavigateNextIcon />
                  </button>
                </Grid>
              </Grid>
              <Grid>
                <Grid container justifyContent="center" mt={2}>
                  {
                    keys.map((date) => {
                      return (
                        <Grid item md={2} sm={3} xs={4} key={date}>
                          <Grid>
                            <p className={classes.weekDay}>
                              {new Date(date).toLocaleDateString('en-US', { weekday: 'long' })}
                            </p>
                            <p className={classes.day}>
                              {new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}
                            </p>
                          </Grid>
                          {
                            slots[date].map((slotObj) => {
                              const sessionTime = slotObj.date;
                              const isDisabled = (new Date() >= new Date(sessionTime)) || slotObj.booked;
                              const slotHour = new Date(sessionTime).getHours();
                              if (slotHour > 0 && slotHour <= 5) return null;
                              return (
                                <Grid key={sessionTime}>
                                  <button
                                    type="button"
                                    className={[classes.slotButton, isDisabled ? classes.disabled : classes.enabled].join(' ')}
                                    onClick={() => { if (!isDisabled) openModal(slotObj); }}
                                    disabled={isDisabled}
                                  >
                                    {new Date(sessionTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                  </button>
                                </Grid>
                              );
                            })
                          }
                        </Grid>
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Container>
          )
      }
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    slots: state.booking.slots,
    session: state.booking.session,
    user: state.user.data,
    loadingSessionData: state.booking.loadingSessionData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step2));
