import React from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CloseImg from '../../assets/images/Close.png';

import CloseSmallImg from '../../assets/images/CloseSmall.png';
import CheckLargeImg from '../../assets/images/Check_large.png';
import CalendarImg from '../../assets/images/Calendar_dots.png';
import InvoiceImg from '../../assets/images/Invoice.png';

const StyledButton = styled(Button)(() => {
  return {
    color: '#b3564e !important',
    textAlign: 'right',
    padding: '0px !important',
    textTransform: 'none !important',
    minWidth: '0px !important',
    '&:hover': {
      background: 'transparent !important',
    },
    minHeight: '0px !important',
  };
});

const styles = makeStyles((theme) => {
  return {
    bookingHeadContainer: {
      padding: 12,
      marginBottom: 8,
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        paddingTop: 8,
      },
    },
    bookingContainer: {
      width: '100%',
      background: '#fff',
      borderRadius: 20,
      padding: '32px 36px',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    bookingIconContainer: {
      background: '#fff7f2',
      padding: '16px',
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        padding: 8,
        borderRadius: 8,
      },
    },
    camImg: {
      [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
    },
    bookingInfoContainer: {
      padding: '0 16px',
      textAlign: 'left',
    },
    midTitle: {
      color: '#333333',
      marginBottom: '16px !important',
      fontSize: '20px !important',
      fontWeight: '600 !important',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    dateTimeText: {
      color: '#595959',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      textAlign: 'left',
    },
    cotactContainer: {
      borderLeft: '1px solid #e8e8e8',
      borderRight: '1px solid #e8e8e8',
      paddingLeft: 40,
      [theme.breakpoints.down('md')]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 0,
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: '1px solid #e8e8e8',
        borderBottom: '1px solid #e8e8e8',
        marginTop: '16px !important',
        marginBottom: '16px !important',
      },
    },
    contactusLinkContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('md')]: {
      },
    },
    notify: {
      paddingLeft: 48,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        marginBottom: '16px !important',
      },
    },
    linkText: {
      color: '#b3564e',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      textAlign: 'left',
    },
    close: {
      fontSize: '16px !important',
      fontWeight: 'bold !important',
      color: '#8c8c8c',
    },
    subText: {
      color: '#333333',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      maxWidth: 220,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      },
      textAlign: 'left',
    },
    notes: {
      color: '#595959',
      textAlign: 'left',
      fontSize: '16px !important',
    },
  };
});

export default function DemoComplete(props) {
  const {
    booking, resetBooking, redirectPaymentPage,
  } = props;
  const classes = styles();

  return (
    <>
      <Grid className={classes.bookingHeadContainer}>
        <Grid container className={classes.bookingContainer}>
          <Grid item md={4} sm={12} xs={12} container alignItems="flex-start">
            <Grid item>
              <Grid className={classes.bookingIconContainer}>
                <img src={CheckLargeImg} alt="done" className={classes.camImg} />
              </Grid>
            </Grid>
            <Grid item xs className={classes.bookingInfoContainer}>
              <Typography className={classes.midTitle}>
                {booking.bookingType === 'demo' ? 'Demo ' : ''}
                Session Completed
              </Typography>
              <Grid container mt={1}>
                <Grid item mr={1}>
                  <img src={CalendarImg} alt="Star" />
                </Grid>
                <Grid item xs>
                  <Typography align="left" className={classes.dateTimeText} noWrap>
                    {moment(booking.startTime).format('MMMM DD, YYYY')}
                    {' '}
                    at
                    {' '}
                    {moment(booking.startTime).format('h:mm a')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.cotactContainer}>
            <Grid>
              <Typography className={classes.midTitle}>Therapist Analysis</Typography>
            </Grid>
            <Grid container mt={1}>
              <Typography className={classes.notes}>{booking.therapistNotes}</Typography>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12} container justifyContent="space-between" className={classes.notify}>
            <Grid item md={9} sm={12} xs={12}>
              <Typography align="left" className={classes.midTitle}>Recommended Plans</Typography>
              <Grid item md={12} className={classes.contactusLinkContainer}>
                <StyledButton onClick={redirectPaymentPage}>
                  <Grid container alignItems="flex-start">
                    <Grid item mr={1}>
                      <img src={InvoiceImg} alt="invoice" />
                    </Grid>
                    <Grid item xs>
                      <Typography align="left" className={classes.linkText} noWrap>View Plans</Typography>
                    </Grid>
                  </Grid>
                </StyledButton>
              </Grid>
            </Grid>
            <Grid item md={3} sm={12} xs={12} container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Hidden mdDown>
                  <StyledButton onClick={resetBooking}>
                    <img src={CloseImg} alt="close" />
                  </StyledButton>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Grid container justifyContent="center">
              <StyledButton onClick={resetBooking} startIcon={<img src={CloseSmallImg} alt="close" />}>
                <Typography className={classes.close}>Close</Typography>
              </StyledButton>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
}
