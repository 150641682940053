import React from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CloseImg from '../../assets/images/Close.png';

import CloseSmallImg from '../../assets/images/CloseSmall.png';
import MeetImg from '../../assets/images/meet.png';
import CalendarImg from '../../assets/images/Calendar_dots.png';
import LinkImg from '../../assets/images/Link.png';
import MessageImg from '../../assets/images/Message.png';
import Modal from './Modal';
import SessionDetails from './SessonDetails';

const StyledButton = styled(Button)(() => {
  return {
    color: '#b3564e !important',
    textAlign: 'right',
    padding: '0px !important',
    textTransform: 'none !important',
    minWidth: '0px !important',
    '&:hover': {
      background: 'transparent !important',
    },
    minHeight: '0px !important',
  };
});

const styles = makeStyles((theme) => {
  return {
    bookingHeadContainer: {
      padding: 12,
      marginBottom: 8,
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    bookingContainer: {
      width: '100%',
      background: '#fff',
      borderRadius: 20,
      padding: '32px 36px',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    bookingIconContainer: {
      background: '#fff7f2',
      padding: '16px',
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        padding: 8,
        borderRadius: 8,
      },
    },
    camImg: {
      [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
    },
    bookingInfoContainer: {
      padding: '0 16px',
      textAlign: 'left',
    },
    midTitle: {
      color: '#333333',
      marginBottom: '16px !important',
      fontSize: '20px !important',
      fontWeight: '600 !important',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    dateTimeText: {
      color: '#595959',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      textAlign: 'left',
    },
    cotactContainer: {
      borderLeft: '1px solid #e8e8e8',
      borderRight: '1px solid #e8e8e8',
      paddingLeft: 40,
      [theme.breakpoints.down('md')]: {
        paddingTop: 16,
        paddingLeft: 0,
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: '1px solid #e8e8e8',
        marginTop: '16px !important',
      },
    },
    contactusLinkContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
    },
    notify: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 48,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        marginTop: 16,
        justifyContent: 'center',
      },
    },
    detailButton: {
      ...theme.button2,
      marginTop: 0,
      [theme.breakpoints.down('md')]: {
        marginTop: 28,
        marginBottom: 16,
      },
    },
    linkText: {
      color: '#b3564e',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      textAlign: 'left',
    },
    close: {
      fontSize: '16px !important',
      fontWeight: 'bold !important',
      color: '#8c8c8c',
    },
    subText: {
      color: '#333333',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      maxWidth: 220,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      },
      textAlign: 'left',
    },
  };
});

export default function Booking(props) {
  const {
    booking, resetBooking, setSnack, sendSessionDetails,
  } = props;
  const classes = styles();
  const [open, setOpen] = React.useState(false);

  const copy = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        setSnack({
          open: true,
          severity: 'success',
          message: 'Link coppied successfully!',
        });
      }).catch((err) => {
        setSnack({
          open: true,
          severity: 'error',
          message: `Link copy Error! ${err.message}`,
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal maxWidth="xs" open={open} handleClose={handleClose} title="Get Session Details" titleSize="h6" titleAlign="flex-start">
        <Grid>
          <SessionDetails handleClose={handleClose} setSnack={setSnack} sendSessionDetails={sendSessionDetails} bookingId={booking && booking._id} />
        </Grid>
      </Modal>
      <Grid className={classes.bookingHeadContainer}>
        <Grid container className={classes.bookingContainer}>
          <Grid item md={5} sm={12} xs={12} container alignItems="center">
            <Grid item>
              <Grid className={classes.bookingIconContainer}>
                <img src={MeetImg} alt="meet" className={classes.camImg} />
              </Grid>
            </Grid>
            <Grid item xs className={classes.bookingInfoContainer}>
              <Typography className={classes.midTitle}>
                {booking.bookingType === 'demo' ? 'Demo ' : ''}
                Session Booked Successfully!
              </Typography>
              <Grid container mt={1}>
                <Grid item mr={1}>
                  <img src={CalendarImg} alt="Star" />
                </Grid>
                <Grid item xs>
                  <Typography align="left" className={classes.dateTimeText} noWrap>
                    {moment(booking.startTime).format('MMMM DD, YYYY')}
                    {' '}
                    at
                    {' '}
                    {moment(booking.startTime).format('h:mm a')}
                  </Typography>
                </Grid>
              </Grid>
              {
                booking.meetingLink
                  ? (
                    <StyledButton onClick={() => { copy(booking.meetingLink); }}>
                      <Grid container mt={1}>
                        <Grid item mr={1}>
                          <img src={LinkImg} alt="Star" />
                        </Grid>
                        <Grid item xs>
                          <Typography color="#b3564e" align="left" className={classes.linkText} noWrap>Copy Session Link</Typography>
                        </Grid>
                      </Grid>
                    </StyledButton>
                  ) : null
              }
            </Grid>
          </Grid>
          <Grid item md={2} sm={12} xs={12} container mt={1} className={classes.cotactContainer}>
            <Grid item md={12} sm={6} xs={6}>
              {/* <Typography className={classes.midTitle}>Contact Us</Typography> */}
              <Grid container>
                <Grid item xs>
                  <Typography align="left" className={classes.subText} noWrap>Have a query?</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={6} xs={6} className={classes.contactusLinkContainer}>
              <StyledButton>
                <Grid container alignItems="flex-start">
                  <Grid item mr={1}>
                    <img src={MessageImg} alt="contact" />
                  </Grid>
                  <Grid item xs>
                    <a href="mailto:support@stamurai.com">
                      <Typography align="left" className={classes.linkText} noWrap>Contact Us</Typography>
                    </a>
                  </Grid>
                </Grid>
              </StyledButton>
            </Grid>
          </Grid>
          <Grid item md={5} sm={12} xs={12} container className={classes.notify}>
            <Grid item md={9} sm={6} xs={12}>
              <button type="button" className={classes.detailButton} onClick={() => { setOpen(true); }}>
                Get Session Details
              </button>
            </Grid>
            <Hidden mdDown>
              <StyledButton onClick={resetBooking}>
                <img src={CloseImg} alt="close" />
              </StyledButton>
            </Hidden>
          </Grid>
          <Hidden mdUp>
            <Grid container justifyContent="center">
              <StyledButton onClick={resetBooking} startIcon={<img src={CloseSmallImg} alt="close" />}>
                <Typography className={classes.close}>Close</Typography>
              </StyledButton>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
}
