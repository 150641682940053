import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Booking from '../pages/Booking';
import Purchase from '../pages/Purchase';
import Home from '../pages/Home';
import Payment from '../pages/Payment';
import User from '../pages/User';

class Body extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => { return <User />; }} />
        <Route path="/home" render={() => { return <Home />; }} />
        <Route path="/schedule" render={() => { return <Booking />; }} />
        <Route path="/purchase" render={() => { return <Purchase />; }} />
        <Route path="/payment" render={() => { return <Payment />; }} />
      </Switch>
    );
  }
}

export default Body;
