import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

export default createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
    ].join(','),
  },
  successColor: '#0bb84c',
  errorColor: '#E53935',
  warningColor: '#FFCC00',
  orangeColor: '#f26e1d',
  redColor: '#ff5f5f',
  greenColor: green[600],
  primaryColor: '#ff7b6f',
  secondaryColor: '#ee6723',
  hoverColor: '#fff7f2',
  boxShadow: '0px 3px 16px #455b6320',
  textColor: '#808080',
  button: {
    padding: '20px 30px',
    fontSize: 22,
    fontWeight: 'bold',
    background: '#ff7b6f',
    border: 'none',
    color: '#fff',
    minWidth: 280,
    borderRadius: 50,
    cursor: 'pointer',
    '&:hover': {
      background: '#ee6723',
    },
    marginTop: 15,
    textTransform: 'none',
    // boxShadow: '0px 3px 16px #455b6320',
    textDecoration: 'none',
  },
  button2: {
    padding: '14px',
    fontSize: 16,
    fontWeight: 'bold',
    background: '#ffb0a9',
    border: 'none',
    color: '#4c2521',
    minWidth: 280,
    borderRadius: 12,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: '#ffb0a9',
    },
    marginTop: 16,
    textTransform: 'none',
    textDecoration: 'none',
  },
});
