export const TOKEN = 'token';

export const PAYMENT_MODES = {
  STRIPE: 'stripe',
  RAZORPAY: 'razorpay',
};

export const BOOKING_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  ABANDONED: 'abandoned',
};
