import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

import Actions from '../actions';

import { checkAndGetNumber } from '../utils/util';

import CheckImg from '../assets/images/check.png';

import WarningImg from '../assets/images/Warning.png';
import EmailImg from '../assets/images/Email.png';
import PhoneImg from '../assets/images/Phone.png';
import UserImg from '../assets/images/User.png';
import StarDarkImg from '../assets/images/StarDark.png';
import NoUpcomingImg from '../assets/images/no_upcoming.png';
import NoHistoryImg from '../assets/images/no_history.png';
import DesignerImg from '../assets/images/designer.png';
import AppBar from '../components/header/AppBar';
import CircularProgress from '../components/app/CircularProgress';
import Session from '../components/app/Session';
import Modal from '../components/app/Modal';
import Profile from '../components/app/Profile';

import Booking from '../components/app/Booking';
import DemoComplete from '../components/app/DemoComplete';
import { BOOKING_STATUS } from '../utils/constants';

const TEXTS = ['Therapist Assessment', 'Recommended duration of therapy', 'Dedicated account manager'];

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    container: {
      padding: '36px 80px',
      background: '#fcfafa',
      [theme.breakpoints.down('md')]: {
        padding: 24,
      },
    },
    iconContainer: {
      width: '64px !important',
      height: 64,
      borderRadius: '50%',
      background: '#fff7f2',
    },
    topVideoCam: {
      fontSize: '32px !important',
      color: '#ffae7d',
    },
    infoContainer: {
      background: '#fff',
      padding: '40px 30px',
      border: '1px solid #e8e8e8',
      borderRadius: 12,
    },
    button: {
      ...theme.button,
      minWidth: 180,
      marginTop: 0,
      fontSize: '18px !important',
      padding: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
        padding: 10,
        minWidth: 140,
      },
    },
    title: {
      color: '#333',
      fontWeight: 500,
      fontSize: '18px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
      },
    },
    boxContainer: {
      padding: 8,
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 5,
      },
    },
    sessionContainer: {
      padding: 15,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    profileContainer: {
      [theme.breakpoints.down('sm')]: {
      },
    },
    nameTitle: {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      color: '#333',
    },
    designation: {
      fontSize: '15px !important',
      color: '#595959',
    },
    sessionCountContainer: {
      paddingLeft: 8,
    },
    expiresOnText: {
      fontSize: '14px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
      fontWeight: '600 !important',
      color: '#b37a58',
      margin: '8px 0 !important',
    },
    emptyText: {
      color: '#595959',
      marginTop: '16px !important',
    },
    subtitle: {
      fontSize: '18px !important',
      color: '#0d0d0d',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
    },
    info: {
      background: '#fff',
      margin: 12,
      border: '1px solid #fafafa',
      padding: 40,
      borderRadius: 20,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
        padding: '40px 15px',
      },
    },
    infoTextContainer: {
      marginBottom: 15,
    },
    infoTitle: {
      fontSize: '36px !important',
      textAlign: 'left',
      marginBottom: '32px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important',
        marginBottom: '24px !important',
      },
    },
    infoText: {
      fontSize: '18px !important',
      color: '#4c3425',
      marginLeft: '8px !important',
      margin: '0px !important',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
      },
    },
    checkImg: {
      marginTop: 3,
      width: 20,
      height: 20,
      [theme.breakpoints.down('sm')]: {
        marginTop: 1,
      },
    },
    designerImg: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    detailContainer: {
      background: '#fff',
      borderRadius: 20,
      margin: '0px 12px !important',
      padding: '20px 24px 30px 24px',
      [theme.breakpoints.down('sm')]: {
        margin: '12px 0px !important',
        height: 'calc(100% - 24px)',
      },
      height: 'calc(100% - 8px)',
    },
    purchaseButton: {
      ...theme.button2,
    },
    scheduleButton: {
      ...theme.button2,
      minWidth: 150,
      marginTop: 0,
      fontSize: '16px !important',
      padding: 12,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
        padding: 8,
        minWidth: 130,
      },
    },
    detailTitleContainer: {
      marginBottom: '24px !important',
      paddingBottom: '8px !important',
      borderBottom: '1px solid #e8e8e8',
      marginLeft: '-8px !important',
      marginRight: '-8px !important',
      paddingLeft: 8,
      paddingRight: 8,
    },
    detailTitle: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      color: '#595959',
      textAlign: 'left',
    },
    editProfile: {
      fontSize: '14px !important',
      fontWeight: '600 !important',
      color: '#b3564e !important',
      textAlign: 'right',
      padding: '0px !important',
      textTransform: 'none !important',
      minWidth: '0px !important',
      '&:hover': {
        background: 'transparent !important',
      },
    },
    subText: {
      color: '#333333',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      maxWidth: 200,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      },
      textAlign: 'left',
    },
    subTextFull: {
      color: '#333333',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      textAlign: 'left',
    },
    description: {
      color: '#595959',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      textAlign: 'left',
    },
  };
});

function Home(props) {
  const classes = useStyles();
  const {
    location, fetchUser, user, getRemainingSessions, sessionCount, getUserTherapist, getAllBookings, setLoading,
    therapist, bookings, resetOrder, sessionCountLoading, resetRemainingSessions, cancelBooking, setSnack,
    insertSessionFeedback, checkAvailableSession, sendSessionDetails,
  } = props;
  const urlParams = new URLSearchParams(location && location.search);
  let { source } = (location && location.state) || {};
  const paramUid = urlParams.get('uid') || localStorage.getItem('uid');
  let paramPhone = urlParams.get('phone');
  paramPhone = paramPhone && checkAndGetNumber(paramPhone, true);
  const paramEmail = urlParams.get('email');
  const page = urlParams.get('page');
  source = source || urlParams.get('leadSource');

  const [redirectPayment, setRedirectPayment] = React.useState(false);
  const [redirectSchedule, setRedirectSchedule] = React.useState(false);
  const [rescheduleBookingId, setRescheduleBookingId] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [pastSessions, setPastSessions] = React.useState([]);
  const [latestSessions, setLatestSessions] = React.useState([]);
  const [profileEdit, setProfileEdit] = React.useState(false);
  const [demoComplete, setDemoComplete] = React.useState(null);
  const [upcomingSession, setUpcomingSession] = React.useState(null);
  const [maxRetry, setMaxRetry] = React.useState(3);

  const isNoSessionAvailable = !sessionCount || !sessionCount.remainingSessions;
  const percentage = sessionCount && sessionCount.remainingSessions && sessionCount.totalSessions
    && (sessionCount.remainingSessions / sessionCount.totalSessions) * 100;

  const startCancelBooking = (data) => {
    cancelBooking(data)
      .then(() => {
        setRefresh(!refresh);
        setSnack({
          open: true,
          message: 'Booking has been cancelled successfully!',
          severity: 'success',
        });
        checkAvailableSession({ uid: user.uid });
      }).catch();
  };

  const insertingFeedback = (data) => {
    insertSessionFeedback(data)
      .then(() => {
        setRefresh(!refresh);
        setSnack({
          open: true,
          message: 'Thanks for your feedback!',
          severity: 'success',
        });
      }).catch();
  };

  const rescheduleBooking = (bookingId) => {
    setRescheduleBookingId(bookingId);
    setRedirectSchedule(true);
  };

  const handleEditClose = () => {
    setProfileEdit(false);
  };

  const onClickEdit = () => {
    setProfileEdit(true);
  };

  const redirectPaymentPage = () => {
    setRedirectPayment(true);
  };

  React.useEffect(() => {
    if (paramUid || paramPhone || paramEmail) fetchUser({ id: paramUid || paramPhone || paramEmail });
  }, [paramUid, paramPhone, paramEmail]);

  React.useEffect(() => {
    if (user) {
      const data = { uid: user.uid };
      localStorage.setItem('uid', user.uid);
      setLoading(true);
      Promise.all([getRemainingSessions(data), getUserTherapist(data), getAllBookings(data)])
        .then(() => { setLoading(false); });
    }
  }, [user, refresh]);

  React.useEffect(() => {
    resetOrder();
  }, []);

  React.useEffect(() => {
    if (bookings) {
      const history = bookings.filter((obj) => {
        const date = moment(obj.startTime).add(30, 'minutes').toDate();
        return date < new Date();
      });
      setPastSessions(history);
      let latest = bookings.filter((obj) => {
        const date = moment().subtract(40, 'minutes').toDate();
        return date <= new Date(obj.startTime);
      });
      latest = latest.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      const latestUpcoming = latest.find((obj) => { return new Date(obj.startTime) >= new Date() && obj.status === BOOKING_STATUS.PENDING; });
      setLatestSessions(latest);
      setUpcomingSession(latestUpcoming);
      const lastBooking = history[0];
      if (lastBooking && lastBooking.status === BOOKING_STATUS.COMPLETED && lastBooking.bookingType === 'demo') {
        setDemoComplete(lastBooking);
      }
    }
  }, [bookings]);

  React.useEffect(() => {
    if (upcomingSession && !upcomingSession.meetingLink) {
      setTimeout(() => {
        const data = { uid: user.uid };
        if (maxRetry > 0) getAllBookings(data);
        setMaxRetry(maxRetry - 1);
      }, 2000);
    }
  }, [upcomingSession]);

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }, 5);
  }, []);

  if (sessionCount && !sessionCountLoading
    && (!sessionCount.totalSessions || (sessionCount.remainingSessions === 1 && sessionCount.totalSessions === 1))) {
    resetRemainingSessions();
    return (
      <Redirect
        to={{
          pathname: '/schedule',
          state: { source },
        }}
        push
      />
    );
  }

  if (user && (page === 'schedule' || redirectSchedule)) {
    return (
      <Redirect
        to={{
          pathname: '/schedule',
          state: { bookingId: rescheduleBookingId },
        }}
        push
      />
    );
  }

  if (!(paramUid || paramPhone || paramEmail) && !user) {
    return <Redirect to="/" push />;
  }

  if (user && (page === 'purchase' || redirectPayment)) {
    return <Redirect to="/purchase" push />;
  }

  return (
    <>
      <AppBar>
        <Modal open={profileEdit} handleClose={handleEditClose} title="Edit Profile" titleSize="h6" titleAlign="flex-start">
          <Grid>
            <Profile uid={paramUid} done />
          </Grid>
        </Modal>
        {
          user
            ? (
              <Grid className={classes.root}>
                <Grid className={classes.container}>
                  {
                    upcomingSession
                      ? (
                        <Booking
                          booking={upcomingSession}
                          resetBooking={() => { setUpcomingSession(null); }}
                          setSnack={setSnack}
                          sendSessionDetails={sendSessionDetails}
                        />
                      ) : null
                  }
                  {
                    demoComplete
                      ? (
                        <DemoComplete
                          redirectPaymentPage={redirectPaymentPage}
                          booking={demoComplete}
                          resetBooking={() => { setDemoComplete(null); }}
                        />
                      ) : null
                  }
                  <Grid>
                    <Grid container>
                      <Grid item md={4} sm={6} xs={12}>
                        <Grid className={classes.detailContainer}>
                          <Grid className={classes.detailTitleContainer}>
                            <Typography className={classes.detailTitle}>Therapist Details</Typography>
                          </Grid>
                          {
                            therapist
                              ? (
                                <Grid container justifyContent="space-between" className={classes.profileContainer}>
                                  <Grid item xs={8}>
                                    <Grid mb={2}>
                                      <Typography align="left" className={classes.nameTitle} noWrap>{therapist.name}</Typography>
                                    </Grid>
                                    <Grid container mt={2}>
                                      <Grid item mr={1}>
                                        <img src={StarDarkImg} alt="Star" />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography align="left" className={classes.subText} noWrap>
                                          {therapist.rating}
                                          {' '}
                                          /
                                          {' '}
                                          {therapist.consultations}
                                          {' '}
                                          consultations
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container mt={1}>
                                      <Grid item mr={1}>
                                        <img src={UserImg} alt="Star" />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography align="left" className={classes.subText} noWrap>{therapist.qualification}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4} container justifyContent="flex-end">
                                    <Avatar
                                      sx={{
                                        width: 80, height: 80, background: '#fff7f2', color: '#b37a58',
                                      }}
                                      src={therapist.photoUrl}
                                      alt="therapist-img"
                                    />
                                  </Grid>
                                  <Grid item xs={12} mt={3}>
                                    <Grid mb={1}>
                                      <Typography className={classes.description}>
                                        About
                                        {' '}
                                        {therapist.name && therapist.name.split(' ')[0]}
                                      </Typography>
                                    </Grid>
                                    <Typography className={classes.subTextFull}>
                                      {therapist.about || 'Specialises in fluency disorders, speech delay, and ASD.'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null
                          }
                        </Grid>
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Grid className={classes.detailContainer}>
                          <Grid className={classes.detailTitleContainer}>
                            <Typography className={classes.detailTitle}>Session Details</Typography>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={8}>
                              <Typography align="left" className={classes.nameTitle}>
                                {sessionCount && sessionCount.remainingSessions ? sessionCount.remainingSessions : 'No'}
                                {' '}
                                Sessions Left
                              </Typography>
                              <Grid container mt={1}>
                                <Grid item xs>
                                  <Typography align="left" className={classes.designation}>
                                    {
                                      sessionCount && sessionCount.remainingSessions !== 0
                                        ? 'Book your sessions before expired' : 'You don\'t have any sessions left in your account.'
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                              {
                                isNoSessionAvailable
                                  ? (
                                    <Avatar
                                      sx={{
                                        width: 80, height: 80, background: '#fff7f2',
                                      }}
                                    >
                                      <img src={WarningImg} alt="no-session" />
                                    </Avatar>
                                  ) : <CircularProgress percentage={percentage} number={sessionCount && sessionCount.remainingSessions} />
                              }
                            </Grid>
                          </Grid>
                          <Grid>
                            {
                              isNoSessionAvailable
                                ? (
                                  <Typography align="left" className={classes.expiresOnText}>
                                    Purchase Sessions to book
                                    <br />
                                    a new session.
                                  </Typography>
                                )
                                : (
                                  <Typography align="left" className={classes.expiresOnText}>
                                    Session expiring on
                                    <br />
                                    {moment(sessionCount && sessionCount.validityEndDate).format('LL')}
                                  </Typography>
                                )
                            }
                          </Grid>
                          <button type="button" className={classes.purchaseButton} onClick={redirectPaymentPage}>
                            Purchase Sessions
                          </button>
                        </Grid>
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <Grid className={classes.detailContainer}>
                          <Grid className={classes.detailTitleContainer} container justifyContent="space-between">
                            <Grid item>
                              <Typography className={classes.detailTitle}>Your Profile</Typography>
                            </Grid>
                            <Grid item>
                              <Button className={classes.editProfile} onClick={onClickEdit}>Edit</Button>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="space-between" className={classes.profileContainer}>
                            <Grid item xs={8}>
                              <Typography align="left" className={classes.nameTitle} noWrap>{user.name || 'You'}</Typography>
                              <Grid container mt={2}>
                                <Grid item mr={1}>
                                  <img src={PhoneImg} alt="Star" />
                                </Grid>
                                <Grid item xs>
                                  <Typography align="left" className={classes.subText} noWrap>{user.phone}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container mt={1}>
                                <Grid item mr={1}>
                                  <img src={EmailImg} alt="Star" />
                                </Grid>
                                <Grid item xs>
                                  <Typography align="left" className={classes.subText} noWrap>{user.email}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                              <Avatar
                                sx={{
                                  width: 80, height: 80, background: '#fff7f2', color: '#b37a58',
                                }}
                                src={user.photoUri}
                                alt="user-img"
                              />
                            </Grid>
                            <Grid item xs={12} mt={3}>
                              <Grid mb={1}>
                                <Typography className={classes.description}>Your Concern</Typography>
                              </Grid>
                              <Typography className={classes.subTextFull}>{user.concern || 'None'}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.info}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6} container justifyContent="flex-start" alignItems="center">
                        <Grid>
                          <Grid>
                            <Typography className={classes.infoTitle}>Things you&#39;ll get post session</Typography>
                          </Grid>
                          <Grid>
                            {
                              TEXTS.map((text) => {
                                return (
                                  <Grid key={text} container alignItems="flex-start" className={classes.infoTextContainer}>
                                    <Grid item>
                                      <img className={classes.checkImg} src={CheckImg} alt="Item" />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography className={classes.infoText}>{text}</Typography>
                                    </Grid>
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <img className={classes.designerImg} src={DesignerImg} alt="designer" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.boxContainer}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography className={classes.title}>Upcoming Sessions</Typography>
                      </Grid>
                      <Grid item>
                        <button type="button" className={classes.scheduleButton} onClick={() => { setRedirectSchedule(true); }}>Schedule</button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} className={classes.sessionContainer}>
                    {
                      latestSessions.map((session) => {
                        return (
                          <Grid key={session._id} item md={4} sm={6} xs={12}>
                            <Session rescheduleBooking={rescheduleBooking} booking={session} cancelBooking={startCancelBooking} isUpcomming />
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                  {
                    !latestSessions || latestSessions.length === 0 ? (
                      <Grid>
                        <img src={NoUpcomingImg} alt="Upcoming" className={classes.designerImg} />
                        <Typography className={classes.emptyText}>Your upcoming sessions will appear here...</Typography>
                      </Grid>
                    ) : null
                  }
                  <br />
                  <Grid className={classes.boxContainer}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography className={classes.title}>Session History</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} className={classes.sessionContainer}>
                    {
                      pastSessions.map((session) => {
                        return (
                          <Grid key={session._id} item md={4} sm={6} xs={12}>
                            <Session booking={session} insertSessionFeedback={insertingFeedback} />
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                  {
                    !pastSessions || pastSessions.length === 0 ? (
                      <Grid>
                        <img src={NoHistoryImg} alt="Upcoming" className={classes.designerImg} />
                        <Typography className={classes.emptyText}>Your session history will appear here...</Typography>
                      </Grid>
                    ) : null
                  }
                </Grid>
              </Grid>
            ) : null
        }
      </AppBar>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    sessionCount: state.session.count,
    sessionCountLoading: state.session.fetching,
    bookings: state.booking.bookings,
    bookingData: state.booking.bookingData,
    therapist: state.session.therapist,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
