import React from 'react';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { validateEmail } from '../../utils/util';

const useStyles = makeStyles((theme) => {
  return {
    sectionContainer: {
      paddingBottom: 0,
      width: '100%',
    },
    paperContainer: {
      marginTop: 15,
      width: '100%',
    },
    inputContainer: {
      textAlign: 'left',
      padding: '8px 16px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        paddingBottom: 16,
      },
      width: '100%',
    },
    input: {
      border: '2px solid transparent',
      borderRadius: 8,
      outline: '1px solid #ffceb1',
      height: 56,
      fontSize: 16,
      color: '#4c3425',
      background: '#fff',
      width: '100%',
      padding: '18px',
      maxWidth: 500,
    },
    inputControl: {
    },
    textInput: {
    },
    phoneInput: {
    },
    codeInput: {
      maxWidth: 64,
    },
    focus: {
      border: '1px solid #000',
    },
    inputTitle: {
      padding: 5,
      fontSize: 14,
      color: '#333',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    submit: {
      ...theme.button,
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  };
});

function SessionDetails(props) {
  const {
    setSnack, sendSessionDetails, bookingId, handleClose,
  } = props;
  const classes = useStyles();
  const getInitialState = (obj = {}) => {
    return {
      email: obj.email || '',
      mobile: obj.mobile || '',
      code: obj.code || '+1',
    };
  };
  const [data, setData] = React.useState(getInitialState());

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = () => {
    const inputData = {};
    Object.keys(data).forEach((key) => {
      inputData[key] = data[key] && data[key].trim();
    });
    inputData.phone = inputData.mobile ? (data.code + data.mobile) : '';
    if (inputData.mobile && (!inputData.code || !inputData.code.startsWith('+'))) {
      setSnack({
        message: 'Invalid country code!',
        severity: 'warning',
      });
      return null;
    }
    if (inputData.email && !validateEmail(inputData.email)) {
      setSnack({
        message: 'Invalid email id!',
        severity: 'warning',
      });
      return null;
    }
    if (!inputData.email && !inputData.phone) {
      setSnack({
        message: 'Please provide valid email or phone!',
        severity: 'warning',
      });
      return null;
    }
    return sendSessionDetails({ bookingId, ...inputData })
      .then(() => {
        handleClose();
        setSnack({
          message: 'Session details sent successfully.',
          severity: 'success',
        });
        setData(getInitialState());
      }).catch();
  };

  return (
    <Grid item container xs={12} justifyContent="center" alignItems="center">
      <Grid item md={12} sm={12} xs={12}>
        <Paper elevation={0} className={classes.paperContainer}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container justifyContent="center" className={classes.sectionContainer}>
              <Grid item md={12} sm={12} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>Email Address</p>
                <div className={classes.inputControl}>
                  <input
                    type="text"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className={[classes.input, classes.textInput].join(' ')}
                    required
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" className={classes.sectionContainer}>
              <Grid item md={12} sm={12} xs={12} className={classes.inputContainer}>
                <p className={classes.inputTitle}>Mobile Number</p>
                <Grid container>
                  <Grid item style={{ paddingRight: 16 }}>
                    <div className={classes.inputControl}>
                      <input
                        type="text"
                        name="code"
                        value={data.code}
                        onChange={handleChange}
                        className={[classes.input, classes.codeInput].join(' ')}
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div className={classes.inputControl}>
                      <input
                        type="number"
                        name="mobile"
                        value={data.mobile}
                        onChange={handleChange}
                        className={[classes.input, classes.phoneInput].join(' ')}
                        required
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container justifyContent="center">
          <button type="button" className={classes.submit} onClick={onSubmit}>
            Send Details
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionDetails;
