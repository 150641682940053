import * as React from 'react';

import { styled } from '@mui/material/styles';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ModifiedCircularProgress = styled(CircularProgress)(() => {
  return {
    [`&.${circularProgressClasses.root}`]: {
      width: '80px !important',
      height: '80px !important',
    },
    [`& .${circularProgressClasses.circle}`]: {
      color: '#ffae7d',
    },
  };
});

const ModifiedCircularProgress2 = styled(CircularProgress)(() => {
  return {
    [`&.${circularProgressClasses.root}`]: {
      width: '80px !important',
      height: '80px !important',
    },
    [`& .${circularProgressClasses.circle}`]: {
      color: '#fff7f2',
    },
  };
});

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <ModifiedCircularProgress2
        variant="determinate"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModifiedCircularProgress
          variant="determinate"
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" sx={{ color: '#b37a58', fontSize: 24, fontWeight: 'bold' }}>
            {props.number < 10 ? `0${props.number}` : props.number}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function CircularStatic(props) {
  const { number, percentage } = props;
  return <CircularProgressWithLabel value={percentage} number={number} thickness={6} />;
}
