import React from 'react';

import clsx from 'clsx';

import { withRouter } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import CheckImg from '../assets/images/check.png';
import IndianTherapistImg from '../assets/images/Indian_therapist.png';
import USTherapistImg from '../assets/images/US_therapist.png';

const THERAPISTS = [
  {
    _id: 'INDIAN',
    name: 'Indian Licensed Therapist',
    img: IndianTherapistImg,
    price: 17,
    info: 'SLP is based and licensed to practice speech therapy in India',
  },
  {
    _id: 'USA',
    name: 'USA Licensed Therapist',
    img: USTherapistImg,
    price: 65,
    info: 'SLP is based and licensed to practice in the US',
  },
];

const styles = makeStyles((theme) => {
  return {
    container: { minHeight: '70vh' },
    submit: theme.button,
    titleContainer: {
      marginTop: '40px !important',
    },
    title: {
      fontSize: '30px !important',
      fontWeight: '500 !important',
    },
    subtitle: {
      color: '#595959',
    },
    imgContainer: {
      marginRight: '15px !important',
      width: 28,
      height: 28,
      marginTop: 8,
    },
    blankImgContainer: {
      border: '1px solid #4c3425',
      borderRadius: '50%',
      width: 24,
      height: 24,
      marginTop: 8,
    },
    checkImg: {
      width: 28,
      height: 28,
    },
    selected: {
      border: '4px solid #ffae7d !important',
      background: 'rgba(255, 206, 177, 0.5) !important',
    },
    paper: {
      border: '4px solid #fff',
      background: '#fff7f2',
      padding: '8px 20px 0 32px',
      margin: 12,
      borderRadius: 24,
      cursor: 'pointer',
    },
    submitContainer: {
      marginTop: 24,
    },
    therapistTitle: {
      color: '#4c3425',
      fontSize: '24px !important',
      fontWeight: '600 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
      },
    },
    therapistSubtitle: {
      marginTop: '4px !important',
      fontSize: '14px !important',
      color: '#4c3425',
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px !important',
      },
    },
    price: {
      marginTop: '24px !important',
      fontSize: '16px !important',
      fontWeight: '600 !important',
      color: '#4c3425',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px !important',
      },
    },
    img: {
      width: '100%',
      height: 'auto',
    },
  };
});

function Therapist(props) {
  const { submitTherapistPreference, uid } = props;
  const classes = styles();
  const [selectedTherapist, setSelectedTherapist] = React.useState({});

  const submit = () => {
    submitTherapistPreference({ therapistPreference: selectedTherapist._id, uid });
  };

  const onSelect = (obj) => {
    setSelectedTherapist(obj);
  };

  React.useEffect(() => {
    setSelectedTherapist(THERAPISTS[1]);
  }, []);

  return (
    <>
      <Container fullWidth>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Choose Therapist</Typography>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid container item md={10} sm={10} xs={12}>
            {
                THERAPISTS.map((data) => {
                  const isSelected = data._id === selectedTherapist._id;
                  return (
                    <Grid key={data._id} item xs={12} md={6} sm={12}>
                      <div
                        role="button"
                        className={clsx(classes.paper, { [classes.selected]: isSelected })}
                        onClick={() => { onSelect(data); }}
                        tabIndex={0}
                      >
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item xs={6}>
                            <Typography align="left" className={classes.therapistTitle}>{data.name}</Typography>
                            <Typography align="left" className={classes.therapistSubtitle}>
                              {data.info}
                            </Typography>
                            <Typography align="left" className={classes.price}>
                              $
                              {data.price}
                              /session
                            </Typography>
                          </Grid>
                          <Grid container item xs={6}>
                            <Grid container alignItems="flex-end" item xs={10}>
                              <img className={classes.img} src={data.img} alt={data.name} />
                            </Grid>
                            <Grid item xs={2}>
                              <Grid className={classes.imgContainer}>
                                {
                                    selectedTherapist._id === data._id
                                      ? <img className={classes.checkImg} src={CheckImg} alt="check" />
                                      : <div className={classes.blankImgContainer} />
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  );
                })
            }
            <Grid container justifyContent="center" alignItems="center" className={classes.submitContainer}>
              <button type="button" className={classes.submit} onClick={submit}>
                Confirm
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withRouter(Therapist);
