import * as Yup from 'yup';

export const currencyFormat = (amount, currency = 'INR') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits: 4,
  }).format(amount);
};

export const userValidationSchema = Yup.object().shape({
  phone: Yup.string('Phone number must be String').required('Phone Number is required!'),
  email: Yup.string().email().required('Email address is required!'),
  name: Yup.string('Name must be a string!'),
  concern: Yup.string('Concern must be a string!').required('Concern is required!'),
});

export const checkAndGetNumber = (number, shouldNotCheck) => {
  let newNumber = number && String(number);
  newNumber = newNumber && newNumber.replace(/[^0-9]/g, '');
  if (!newNumber) return newNumber;
  if (shouldNotCheck) return `+${newNumber}`;
  if (newNumber.startsWith('0')) newNumber = newNumber.slice(1);
  if (newNumber.length > 10 && !newNumber.startsWith('+')) return `+${newNumber}`;
  if (newNumber.length === 10) return `+91${newNumber}`;
  return newNumber;
};

export const isInternation = (number) => {
  if (!number || number.startsWith('+91')) return false;
  return true;
};

export function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
