import React from 'react';

import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AppBar from '../components/header/AppBar';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      minHeight: '80vh',
      width: '100%',
    },
    button: {
      ...theme.button,
    },
  };
});

function Payment() {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = React.useState(5);
  const [isRedirect, setIsRedirect] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    if (!timeLeft) {
      return setIsRedirect(true);
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => { return clearInterval(intervalId); };
  }, [timeLeft]);

  if (redirect) {
    return <Redirect to="/schedule" />;
  }

  return (
    <>
      <AppBar>
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography>
                {
                    !isRedirect ? 'Please make the payment in the newly opened tab. Waiting for payment confirmation.....'
                      : 'Thank you for your interest in Stamurai speech therapy. Please click the button below to schedule your therapy session.'
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                isRedirect
                  ? <button className={classes.button} type="button" onClick={() => { setRedirect(true); }}>Schedule Now</button>
                  : null
                }
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}

export default withRouter(Payment);
