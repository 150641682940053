import React from 'react';

import AppBar from '../components/header/AppBar';
import BookingStepper from '../components/booking/Stepper';

function Booking() {
  return (
    <>
      <AppBar>
        <div>
          <BookingStepper />
        </div>
      </AppBar>
    </>
  );
}

export default Booking;
