import React from 'react';

import Alert from '@mui/material/Alert';

import Close from '@mui/icons-material/Close';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

function SnackbarProgress(props) {
  const {
    open, closeSnack, severity, message, position,
  } = props;
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: position || 'top',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={closeSnack}
        action={(
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnack}>
              <Close fontSize="small" />
            </IconButton>
          </>
          )}
      >
        <Alert onClose={closeSnack} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SnackbarProgress;
