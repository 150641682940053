import React from 'react';

import { makeStyles } from '@mui/styles';

// import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';

const useStyles = makeStyles(() => {
  return {
    root: {
      width: '100%',
    },
    container: {
      width: '100%',
    },
  };
});

export default function CustomizedSteppers() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => { return prevActiveStep + 1; });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => { return prevActiveStep - 1; });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step2 handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return <Step3 handleNext={handleNext} handleBack={handleBack} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <div>
          <div className={classes.container}>{getStepContent(activeStep)}</div>
        </div>
      </div>
    </div>
  );
}
