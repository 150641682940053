import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Logo from '../assets/images/Logo.png';

import Actions from '../actions';

import BackgroundBlob from '../assets/images/Vector.png';

import Profile from '../components/app/Profile';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      minHeight: '100vh',
      background: `#fffaf3 url(${BackgroundBlob}) no-repeat center bottom`,
      backgroundSize: '100%',
      [theme.breakpoints.down('sm')]: {
        background: `#fffaf3 url(${BackgroundBlob}) no-repeat center bottom`,
      },
    },
    logoContainer: {
      minHeight: '20vh',
      [theme.breakpoints.down('sm')]: {
        minHeight: '10vh',
        justifyContent: 'flex-start !important',
      },
      padding: '0 20px',
    },
    container: {
      padding: '40px 30px',
      background: '#fff',
      width: '60% !important',
      borderRadius: 20,
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        padding: '32px 24px',
      },
      margin: '20px',
    },
    title: {
      color: '#0d0d0d',
      fontSize: '32px !important',
      fontWeight: '500 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        textAlign: 'left',
      },
    },
    titleContainer: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    subtitle: {
      color: '#595959',
      marginTop: '8px !important',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },
  };
});

function User(props) {
  const {
    user, location, fetchUser,
  } = props;
  const classes = useStyles();
  const urlParams = new URLSearchParams(location && location.search);
  const uid = urlParams.get('uid') || localStorage.getItem('uid');
  const source = urlParams.get('leadSource');
  const [redirect, setRedirect] = React.useState(false);

  const done = () => {};

  React.useEffect(() => {
    if (user && user.uid) setRedirect(true);
  }, [user]);

  React.useEffect(() => {
    if (uid) fetchUser({ id: uid });
  }, [uid]);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { source },
        }}
        push
      />
    );
  }

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" className={classes.logoContainer}>
        <Grid item>
          <img
            src={Logo}
            alt="logo"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title}>Book A Free Slot</Typography>
            <Typography className={classes.subtitle}>
              We will connect you with a therapist best suited for your needs
            </Typography>
          </Grid>
          <Profile uid={uid} done={done} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
