const initialState = {
  loading: false,
};

export default function bookingReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'FETCHING_SLOTS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED_SLOTS':
      return {
        ...state,
        loading: false,
        slots: payload,
      };
    case 'CREATING_BOOKING':
      return {
        ...state,
        loading: true,
      };
    case 'CREATED_BOOKING':
      return {
        ...state,
        loading: false,
        bookingData: payload,
      };
    case 'RESCHEDULING_BOOKING':
      return {
        ...state,
        loading: true,
      };
    case 'RESCHEDULED_BOOKING':
      return {
        ...state,
        loading: false,
        bookingData: payload,
      };
    case 'CHECKING_SESSION':
      return {
        ...state,
        loadingSessionData: true,
      };
    case 'CHECKED_SESSION':
      return {
        ...state,
        loadingSessionData: false,
        session: payload,
      };
    case 'FETCHING_ALL_BOOKINGS':
      return {
        ...state,
      };
    case 'FETCHED_ALL_BOOKINGS':
      return {
        ...state,
        bookings: payload,
      };
    case 'RESET_SESSION':
      return {
        ...state,
        loading: false,
        session: null,
      };
    case 'RESET_BOOKING':
      return {
        ...state,
        loading: false,
        bookingData: null,
      };
    case 'SENDING_SESSION_DETAILS':
      return {
        ...state,
      };
    case 'SENT_SESSION_DETAILS':
      return {
        ...state,
      };
    default:
      return state;
  }
}
