import React from 'react';

import { makeStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseImg from '../../assets/images/Close.png';

const styles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
    },
    paper: {
      padding: 30,
      minWidth: 360,
      maxWidth: 1000,
      [theme.breakpoints.down('sm')]: {
        minWidth: 340,
        padding: 20,
      },
      [theme.breakpoints.up('sm')]: {
      },
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'centet',
    },
  };
});

export default function TransitionsModal(props) {
  const {
    children, open, handleClose, title, titleAlign, titleSize, maxWidth,
  } = props;
  const classes = styles();

  return (
    <div>
      <Dialog
        className={classes.modal}
        open={open || false}
        onClose={handleClose}
        disableAutoFocus
        disableEnforceFocus
        fullWidth
        maxWidth={maxWidth || 'md'}
      >
        <Fade in={open || false}>
          <Paper elevation={0} className={classes.paper}>
            <Grid container justify="flex-end" alignItems="center">
              <Grid container item xs justifyContent={titleAlign || 'center'} alignItems="center">
                <Typography className={classes.title} variant={titleSize || 'subtitle1'}><b>{title}</b></Typography>
              </Grid>
              <Grid item justify="flex-end">
                <IconButton size="small" onClick={handleClose} sx={{ padding: 0 }}>
                  <img src={CloseImg} alt="close" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid className={classes.content}>
              {children}
            </Grid>
          </Paper>
        </Fade>
      </Dialog>
    </div>
  );
}
