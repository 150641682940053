import { combineReducers } from 'redux';

import bookingReducer from './bookingReducer';
import progressReducer from './progressReducer';
import userReducer from './userReducer';
import sessionReducer from './sessionReducer';

export default combineReducers({
  booking: bookingReducer,
  progress: progressReducer,
  user: userReducer,
  session: sessionReducer,
});
