import React from 'react';

import { withRouter, Redirect } from 'react-router-dom';

import { styled, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Img from '../../../assets/images/Illustration.png';
import BackgroundBlob from '../../../assets/images/Intersect.png';
import BackgroundBlobMobile from '../../../assets/images/Intersect_mobile.png';

const Image = styled('img')(({ theme }) => {
  return {
    [theme.breakpoints.down('md')]: {
      width: '80%',
      height: 'auto',
    },
  };
});

const styles = makeStyles((theme) => {
  return {
    back: theme.button,
    subtitle: {
      color: '#595959',
    },
    background: {
      background: `#fff url(${BackgroundBlob}) no-repeat center bottom`,
      minHeight: '92vh',
      [theme.breakpoints.down('sm')]: {
        background: `#fff url(${BackgroundBlobMobile}) no-repeat right bottom`,
        backgroundSize: '100%',
      },
    },
  };
});

function Step3() {
  const classes = styles();
  const [redirect, setRedirect] = React.useState(false);
  if (redirect) {
    return <Redirect to="/home" push />;
  }
  return (
    <div className={classes.background}>
      <Container>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item md={6} sm={6} xs={12} mt={5} p={1}>
            <Typography align="left" variant="h4"><b>Thank you for scheduling your consultation!</b></Typography>
            <Typography variant="h6" align="left" mt={3} className={classes.subtitle}>
              The consultation is a 30 minutes interaction with one of our certified speech therapists.
              The interaction will happen on Google Meet on the date and time you selected.
            </Typography>
            <Typography variant="h6" align="left" mt={3} className={classes.subtitle}>
              You should receive a text message and email shortly to confirm your appointment along with the meeting link.
              We will also send a reminder text 30 mins prior to your call. We look forward to speaking with you!
            </Typography>
            <Grid container justifyContent="flex-start">
              <button type="button" className={classes.back} onClick={() => { setRedirect(true); }}>Back To Home</button>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={12} mt={5}>
            <Image src={Img} alt="Illustration" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withRouter(Step3);
