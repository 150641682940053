import Axios from 'axios';

import config from '../config.json';

const URI = config.URI;
const DEFAULT_HEADER = { headers: { 'Content-Type': 'application/json' } };
const GET = 'get';
const POST = 'post';

const calling = (url, method, data) => {
  if (method === POST) {
    return Axios.post(url, data, DEFAULT_HEADER).then((response) => {
      return response.data;
    });
  }
  return Axios.get(url, { params: data }, DEFAULT_HEADER).then((response) => {
    return response.data;
  });
};

const callApi = (url, method, data) => {
  return calling(url, method, data);
};

export function fetchOrCreateUser(data) {
  const url = `${URI}/api/users/create-firebase-auth-user`;
  return callApi(url, POST, data);
}

export function fetchUser(data) {
  const url = `${URI}/api/cloud-task/fetch-user`;
  return callApi(url, GET, data);
}

export function fetchSlots(data) {
  const url = `${URI}/api/therapist-slot/days-slots`;
  return callApi(url, GET, data);
}

export function createBooking(data) {
  const url = `${URI}/api/therapist-slot/booking`;
  return callApi(url, POST, data);
}

export function resheduleBooking(data) {
  const url = `${URI}/api/therapist-slot/reschedule-booking`;
  return callApi(url, POST, data);
}

export function checkAvailableSession(data) {
  const url = `${URI}/api/user-authorized-sessions/add-or-get-user-session`;
  return callApi(url, GET, data);
}

export function getRemainingSessions(data) {
  const url = `${URI}/api/user-authorized-sessions/remaining`;
  return callApi(url, GET, data);
}

export function getUserTherapist(data) {
  const url = `${URI}/api/therapist/get-therapist-for-user`;
  return callApi(url, GET, data);
}

export function getAllBookings(data) {
  const url = `${URI}/api/therapist-slot/get-all-bookings`;
  return callApi(url, GET, data);
}

export function createOrder(data) {
  const url = `${URI}/api/payment-order/create-order`;
  return callApi(url, POST, data);
}

export function getOffers(data) {
  const url = `${URI}/api/payment-order/offers`;
  return callApi(url, GET, data);
}

export function cancelBooking(data) {
  const url = `${URI}/api/therapist-slot/cancel-booking`;
  return callApi(url, POST, data);
}

export function insertSessionFeedback(data) {
  const url = `${URI}/api/therapist-slot/session-feedback`;
  return callApi(url, POST, data);
}

export function updateUser(data) {
  const url = `${URI}/api/cloud-task/update-user`;
  return callApi(url, POST, data);
}

export function sendSessionDetails(data) {
  const url = `${URI}/api/cloud-task/send-booking-details`;
  return callApi(url, POST, data);
}
