import React from 'react';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';

import moment from 'moment';
import { BOOKING_STATUS } from '../../utils/constants';

import CheckImg from '../../assets/images/check.png';
import CloseCircleImg from '../../assets/images/Close_circle.png';
import VideocamImg from '../../assets/images/Video.png';
import CalendarImg from '../../assets/images/Calendar.png';
import StarImg from '../../assets/images/Star.png';
import StarBorderImg from '../../assets/images/StarBorder.png';
import StarSmallImg from '../../assets/images/StarSmall.png';
import StarBorderSmallImg from '../../assets/images/StarBorderSmall.png';
import LiveImg from '../../assets/images/live.png';
import Modal from './Modal';

const styles = makeStyles((theme) => {
  return {
    container: {
      padding: '16px 20px',
      background: '#fff1f0',
      border: '1px solid #fafafa',
      width: '100%',
      borderRadius: 12,
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    cancelledContainer: {
      border: '2px solid #ff7b6f',
    },
    cancelled: {
      color: '#ff5242',
    },
    button: {
      ...theme.button2,
      minWidth: '80%',
      fontSize: 14,
      padding: 10,
      marginTop: 0,
      fontWeight: 600,
      display: 'block',
      border: 'solid 1px #ff7b6f',
      color: '#4c2521',
      borderRadius: 10,
    },
    saveButton: {
      ...theme.button,
      minWidth: '180px',
      fontSize: 20,
      padding: 15,
      marginTop: 30,
      fontWeight: 600,
    },
    cancelButton: {
      minWidth: '100% !important',
      fontSize: '14px !important',
      padding: '6px !important',
      fontWeight: '600 !important',
      background: 'transparent',
      color: '#994c45 !important',
      border: 'solid 1px #994c45 !important',
      borderRadius: '10px !important',
      textTransform: 'none !important',
      minHeight: '0 !important',
    },
    cancelSessionButton: {
      ...theme.button,
      minWidth: '60%',
      fontSize: 22,
      padding: 15,
      marginTop: 40,
      fontWeight: 'bold',
    },
    discardButton: {
      fontSize: 22,
      border: 'none',
      background: '#fff',
      marginTop: 20,
      marginBottom: 20,
      color: '#ff7b6f',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    dateContainer: {
      background: '#fff',
      padding: '8px 10px',
      borderRadius: 4,
      width: 44,
    },
    dateText: {
      fontSize: '20px !important',
      color: '#333',
      fontWeight: '600 !important',
      lineHeight: '1em !important',
    },
    monthText: {
      fontSize: '14px !important',
      color: '#333',
      fontWeight: '600 !important',
      lineHeight: '1em !important',
    },
    textArea: {
      background: '#fff',
      width: '70%',
      minWidth: '100%',
      borderRadius: 16,
      padding: 20,
      fontSize: '14px !important',
      color: '#595959',
      border: '1px solid #ffceb1',
    },
    ratingContainer: {
      paddingTop: '20px !important',
      paddingBottom: '15px !important',
    },
    bookingTitle: {
      fontSize: '16px !important',
      color: '#402c29',
      lineHeight: '2em !important',
      fontWeight: '600 !important',
    },
    bookingSubtitle: {
      fontSize: '14px !important',
      color: '#402c29',
    },
    camIncon: {
      color: '#e8e8e8',
    },
    reasonText: {
      fontSize: '12px !important',
      color: '#ff5242',
      marginLeft: '5px !important',
      textTransform: 'capitalize',
    },
    subtitle: {
      fontSize: '12px !important',
      color: '#4c3425 !important',
      marginLeft: '5px !important',
    },
    functionButton: {
      fontSize: '12px !important',
      color: '#4c3425 !important',
      padding: '0px !important',
      textTransform: 'none !important',
      '&:hover': {
        background: 'transparent !important',
      },
    },
    liveIconContainer: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    liveText: {
      fontSize: '12px !important',
      color: '#ffae7d',
    },
    cancelTitle: {
      fontSize: '32px !important',
      color: '#333',
      lineHeight: '2em !important',
      fontWeight: '500 !important',
    },
    cancelText: {
      fontSize: '20px !important',
      color: '#595959',
    },
    starBorderIcon: {
    },
    starIcon: {
    },
  };
});

const MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getMinutesDiff = (date1, date2) => {
  const newDate1 = moment(date1);
  const newDate2 = moment(date2);
  return newDate1.diff(newDate2, 'minutes');
};

export default function Session(props) {
  const {
    booking, isUpcomming, cancelBooking, insertSessionFeedback, rescheduleBooking,
  } = props;
  const classes = styles();
  const therapist = booking.therapist || {};
  const isCancelled = booking.status === BOOKING_STATUS.CANCELLED;
  const [open, setOpen] = React.useState(false);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [userFeedbackRating, setUserFeedbackRating] = React.useState(0);
  const [userFeedbackDetail, setUserFeedbackDetail] = React.useState('');
  const startTime = moment(booking.startTime).subtract(10, 'minutes').toDate();
  const [startIn, setStartIn] = React.useState(getMinutesDiff(startTime, new Date()));

  const isLive = startIn <= 0;

  const openModal = (rating = 5) => {
    if (!booking.userFeedbackRating) {
      setOpen(true);
      setUserFeedbackRating(rating);
    }
  };

  const setRating = (rating) => {
    setUserFeedbackRating(rating);
  };

  const handleClose = () => {
    setOpen(false);
    setUserFeedbackRating(0);
  };

  const handleCancel = () => {
    setOpenCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const cancellingBooking = () => {
    handleCloseCancelModal();
    cancelBooking({ booking: booking._id, uid: booking.uid });
  };

  const save = () => {
    setOpen(false);
    insertSessionFeedback({
      booking: booking._id,
      userFeedbackDetail,
      userFeedbackRating,
      uid: booking.uid,
    });
  };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setStartIn(startIn - 1);
    }, 15000);

    return () => { return clearInterval(intervalId); };
  }, [startIn]);

  React.useEffect(() => {
    if (booking.userFeedbackRating) setUserFeedbackRating(booking.userFeedbackRating);
  }, [booking.userFeedbackRating]);

  return (
    <>
      <Modal open={openCancelModal} handleClose={handleCloseCancelModal} maxWidth="xs">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" className={classes.cancelTitle}>Cancel Session</Typography>
            <Typography align="center" className={classes.cancelText}>
              Are you sure you want to cancel this session?
              {' '}
              <b>Note: you cannot undo this action.</b>
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <button type="button" className={classes.cancelSessionButton} onClick={cancellingBooking}>Yes, Cancel</button>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <button type="button" className={classes.discardButton} onClick={handleCloseCancelModal}>Discard</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={open} handleClose={handleClose} titleAlign="flex-start" title="Rate Your Session" titleSize="h6" maxWidth="xs">
        <Grid container justifyContent="center" alignItems="center" style={{ padding: 0 }}>
          <Grid item xs={12} container justifyContent="center" alignItems="center" className={classes.ratingContainer}>
            <Rating
              name="simple-controlled"
              value={userFeedbackRating}
              onChange={(event, newValue) => {
                setRating(newValue || userFeedbackRating);
              }}
              emptyIcon={<img src={StarBorderImg} className={classes.starBorderIcon} alt="rating" />}
              icon={<img src={StarImg} className={classes.starIcon} alt="rating" />}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <TextareaAutosize
              minRows={8}
              placeholder="Add comments (optional)"
              className={classes.textArea}
              value={userFeedbackDetail}
              onChange={(e) => { setUserFeedbackDetail(e.target.value); }}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <button type="button" className={classes.saveButton} onClick={save}>Save</button>
          </Grid>
        </Grid>
      </Modal>
      <Grid className={isCancelled ? [classes.container, classes.cancelledContainer].join(' ') : classes.container}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={7} justifyContent="flex-start" alignItems="center">
            {
              isCancelled || isUpcomming
                ? <img src={VideocamImg} alt="videocam" className={isCancelled ? classes.cancelled : classes.camIncon} />
                : <img className={classes.checkImg} width="16px" height="16px" src={CheckImg} alt="check" />
            }
            {
              isCancelled ? <Typography noWrap className={classes.reasonText}>Cancelled</Typography>
                : !isUpcomming && <Typography noWrap className={classes.subtitle}>Completed</Typography>
            }
            {
              isUpcomming && !isCancelled && isLive
                ? (
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item className={classes.liveIconContainer}>
                        <img src={LiveImg} alt="live" />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.liveText}>Live</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null
            }
          </Grid>
          <Grid item container xs={5} justifyContent="flex-end">
            {
              !isUpcomming && !isCancelled
                ? (
                  <Grid item xs={12} container justifyContent="flex-end">
                    {
                      booking.userFeedbackRating
                        ? (
                          <Rating
                            name="simple-controlled"
                            value={userFeedbackRating}
                            onChange={(event, newValue) => {
                              openModal(newValue || userFeedbackRating);
                            }}
                            readOnly={!!booking.userFeedbackRating}
                            emptyIcon={<img src={StarBorderSmallImg} className={classes.starBorderIcon} alt="rating" />}
                            icon={<img src={StarSmallImg} className={classes.starIcon} alt="rating" />}
                          />
                        ) : (
                          <Button
                            onClick={() => { openModal(); }}
                            className={classes.functionButton}
                            startIcon={<img src={StarSmallImg} alt="rating" />}
                          >
                            Rate Your Session
                          </Button>
                        )
                    }
                  </Grid>
                ) : null
            }
            {
              isUpcomming && !isCancelled && !isLive
                ? (
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Grid>
                      <Button
                        onClick={() => { rescheduleBooking(booking._id); }}
                        className={classes.functionButton}
                        startIcon={<img src={CalendarImg} alt="reschedule" />}
                      >
                        Reschedule
                      </Button>
                    </Grid>
                  </Grid>
                ) : null
            }
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={8} justifyContent="flex-start" alignItems="center">
            <Grid item xs={3}>
              <Grid className={classes.dateContainer}>
                <Typography className={classes.dateText}>{new Date(booking.startTime).getDate()}</Typography>
                <Typography className={classes.monthText}>{MONTH_NAME[new Date(booking.startTime).getMonth()]}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Typography align="left" className={classes.bookingTitle}>
                {booking.bookingType === 'demo' ? 'Demo' : 'Therapist'}
                {' '}
                Session
              </Typography>
              <Typography align="left" className={classes.bookingSubtitle}>
                {new Date(booking.startTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                {' '}
                <Typography component="span" variant="h6" sx={{ lineHeight: 1 }}>•</Typography>
                {' '}
                30 min
              </Typography>
              <Typography align="left" noWrap className={classes.bookingSubtitle}>
                {therapist.name}
                {' '}
                <Typography component="span" variant="h6" sx={{ lineHeight: 1 }}>•</Typography>
                {' '}
                {therapist.designation}
              </Typography>
            </Grid>
          </Grid>
          {
            isUpcomming && !isCancelled
              ? (
                <Grid item xs={4}>
                  <Grid>
                    {
                      isLive ? <a href={booking.meetingLink} rel="noreferrer" target="_blank" className={classes.button}>Join Now</a>
                        : (
                          <Button startIcon={<img src={CloseCircleImg} alt="close" />} className={classes.cancelButton} onClick={handleCancel}>
                            Cancel
                          </Button>
                        )
                    }
                  </Grid>
                </Grid>
              ) : null
          }
        </Grid>
      </Grid>
    </>
  );
}
