const initialState = {
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'CREATING_USER':
      return {
        ...state,
      };
    case 'CREATED_USER':
      return {
        ...state,
        data: payload,
      };
    case 'FETCHING_USER':
      return {
        ...state,
      };
    case 'FETCHED_USER':
      return {
        ...state,
        data: payload,
      };
    case 'UPDATING_USER':
      return {
        ...state,
      };
    case 'UPDATED_USER':
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
}
