import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';

import Logo from '../../assets/images/Logo.png';
import LogoutImg from '../../assets/images/Logout.png';
import BackImg from '../../assets/images/Back.png';

const StyledButton = styled(Button)(({ theme }) => {
  return {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    marginLeft: 30,
    color: '#b3564e',
    fontWeight: '500',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    '&:hover': {
      background: 'transparent',
    },
  };
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    appbar: {
    },
    titleBar: {
      background: '#fff',
      padding: '5px 80px !important',
      [theme.breakpoints.down('md')]: {
        padding: '5px 16px !important',
      },
    },
  };
});

function Header({ children }) {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);

  const goto = () => {
    setRedirect(true);
  };

  const logout = () => {
    localStorage.clear();
  };

  if (redirect) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" className={classes.appBar}>
        <Toolbar className={classes.titleBar}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <img
                src={Logo}
                alt="logo"
              />
            </Grid>
            <Grid item>
              <Hidden>
                {
                  window.location.pathname.startsWith('/home')
                    ? <StyledButton href="/" onClick={logout} startIcon={<img src={LogoutImg} alt="logout" />}>Logout</StyledButton>
                    : <StyledButton onClick={goto} startIcon={<img src={BackImg} alt="back" />}>Back</StyledButton>
                }
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      { children }
    </div>
  );
}

export default withRouter(Header);
