import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Actions from '../../actions';

import Snackbar from './Snackbar';
import Loader from './BackdropLoader';

function Progress(props) {
  const {
    snackData, error, resetError, resetSnack, loading, resetLoading,
  } = props;
  const [snack, setSnack] = React.useState({});

  const closeSnack = () => {
    setSnack({});
  };

  React.useEffect(() => {
    if (error && error.message) {
      setSnack({
        open: true,
        severity: 'error',
        message: error.message,
      });
      resetLoading();
    } else if (!error && snackData) {
      setSnack({
        open: true,
        severity: snackData.severity || 'success',
        message: snackData.message || 'Success!',
      });
      resetLoading();
    }
    resetError();
    resetSnack();
  }, [resetSnack, resetError, error, snackData, resetLoading, loading]);

  return (
    <>
      <Snackbar
        open={snack.open}
        closeSnack={closeSnack}
        severity={snack.severity}
        message={snack.message}
      />
      <Loader loading={loading} />
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

function mapStateToProps(state) {
  return {
    error: state.progress.error,
    snackData: state.progress.snack,
    loading: state.progress.loading,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Progress));
