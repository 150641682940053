import React from 'react';

import { makeStyles } from '@mui/styles';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: '#fff',
    },
  };
});

function BackdropLoader(props) {
  const { loading } = props;

  const classes = useStyles();

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default BackdropLoader;
