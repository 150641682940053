import React from 'react';

import './App.css';

import Body from './routes/Body';
import Progress from './components/progress/Progress';

function App() {
  return (
    <>
      <div className="App">
        <Progress />
        <Body />
      </div>
    </>
  );
}

export default App;
