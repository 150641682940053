const initialState = {
  loading: false,
};

export default function progressReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ERROR':
      return { ...state, error: payload };
    case 'RESET_ERROR':
      return { ...state, error: null };
    case 'SET_SNACK':
      return { ...state, snack: payload };
    case 'RESET_SNACK':
      return { ...state, snack: null };
    case 'SET_LOADING':
      return { ...state, loading: payload === undefined ? true : payload };
    case 'RESET_LOADING':
      return { ...state, loading: false };
    default:
      return state;
  }
}
