import * as Axios from '../api/axios';

const notify = function (dispatch, error, type) {
  const message = (error.error && error.error.response && error.error.response.data
    && ((error.error.response.data.error && error.error.response.data.error.message)
    || (error.error.response.data && error.error.response.data.message) || error.error.response.data)) || 'Server Error!';
  const newError = new Error(message);
  dispatch({
    type: type || 'SET_ERROR',
    payload: newError,
  });
  return Promise.reject(newError);
};

export function resetError() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ERROR',
      payload: null,
    });
  };
}

export function setSnack(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_SNACK',
      payload: data,
    });
  };
}

export function resetSnack() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_SNACK',
      payload: null,
    });
  };
}

export function setLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: bool,
    });
  };
}

export function resetLoading() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_LOADING',
      payload: false,
    });
  };
}

export function resetOrder() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ORDER',
      payload: null,
    });
  };
}

export function resetRemainingSessions() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_REMAINING_SESSIONS',
      payload: null,
    });
  };
}

export function resetSession() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_SESSION',
      payload: null,
    });
  };
}

export function resetBooking() {
  return (dispatch) => {
    dispatch({
      type: 'RESET_BOOKING',
      payload: null,
    });
  };
}

export const fetchOrCreateUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATING_USER',
    });
    return Axios.fetchOrCreateUser(data).then((resData) => {
      dispatch({
        type: 'CREATED_USER',
        payload: resData,
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const fetchUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_USER',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.fetchUser(data).then((resData) => {
      dispatch({
        type: 'FETCHED_USER',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATING_USER',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.updateUser(data).then((resData) => {
      dispatch({
        type: 'UPDATED_USER',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const fetchSlots = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_SLOTS',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.fetchSlots(data).then((resData) => {
      dispatch({
        type: 'FETCHED_SLOTS',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const createBooking = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATING_BOOKING',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.createBooking(data).then((resData) => {
      dispatch({
        type: 'CREATED_BOOKING',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const resheduleBooking = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'RESCHEDULING_BOOKING',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.resheduleBooking(data).then((resData) => {
      dispatch({
        type: 'RESCHEDULED_BOOKING',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const checkAvailableSession = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'CHECKING_SESSION',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.checkAvailableSession(data).then((resData) => {
      dispatch({
        type: 'CHECKED_SESSION',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const getRemainingSessions = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_REMAINING_SESSIONS',
    });
    return Axios.getRemainingSessions(data).then((resData) => {
      dispatch({
        type: 'FETCHED_REMAINING_SESSIONS',
        payload: resData,
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const getUserTherapist = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_USER_THERAPIST',
    });
    return Axios.getUserTherapist(data).then((resData) => {
      dispatch({
        type: 'FETCHED_USER_THERAPIST',
        payload: resData,
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const getAllBookings = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_ALL_BOOKINGS',
    });
    return Axios.getAllBookings(data).then((resData) => {
      dispatch({
        type: 'FETCHED_ALL_BOOKINGS',
        payload: resData,
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const createOrder = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'CREATING_ORDER',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.createOrder(data).then((resData) => {
      dispatch({
        type: 'CREATED_ORDER',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const getOffers = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'FETCHING_OFFERS',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.getOffers(data).then((resData) => {
      dispatch({
        type: 'FETCHED_OFFERS',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error }).catch(() => { return Promise.resolve(); });
    });
  };
};

export const cancelBooking = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'CANCELLING_BOOKING',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.cancelBooking(data).then((resData) => {
      dispatch({
        type: 'CANCELLED_BOOKING',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const insertSessionFeedback = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'INSERTING_SESSION_FEEDBACK',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.insertSessionFeedback(data).then((resData) => {
      dispatch({
        type: 'INSERTED_SESSION_FEEDBACK',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};

export const sendSessionDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'SENDING_SESSION_DETAILS',
    });
    dispatch({
      type: 'SET_LOADING',
    });
    return Axios.sendSessionDetails(data).then((resData) => {
      dispatch({
        type: 'SENT_SESSION_DETAILS',
        payload: resData,
      });
      dispatch({
        type: 'RESET_LOADING',
      });
    }, (error) => {
      return notify(dispatch, { error });
    });
  };
};
